.HeaderDashContain{
    background-color: var(--colorNavegacion);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
    width: 100%;

    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form{
  width: auto;
  padding: 10px;
  margin: 0 auto;
  margin-top: 150px;
  font-family: sans-serif;
  background: #fff
}
.form-item{
  float: left;
  position: relative;
  margin-bottom: 15px
}
.form-item input{
  display: block;
  width: 100%;
  height: 30px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all .3s ease;
  padding: 0 15px
}
.form-item input:focus{
  border-color: var(--color1);
}
.form-item label{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 10px;
  font-size: 12px;
  font-weight: bold;
  background: #fff;
  padding: 0 10px;
  color: #424242;
  transition: all .3s ease
}
.form-item input:focus + label,
.form-item input:valid + label{
  font-size: 11px;
  top: -5px
}
.form-item input:focus + label{
  color: var(--color1);
}


.json-preview {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
  font-family: monospace;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.deFlexHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
 gap: 2rem;
}
@media (max-width: 1024px){
  .HeaderDashContain{
    flex-direction: column;
    padding-top: 1rem;
  }
  .deFlexHeader{
 
    gap: 10px;
   padding: 10px;
   
  }
}
