.inputsAuth{
    display: flex;
   
    flex-direction: column;
    
   
}
.formContain{
    display: flex;
    flex-direction: column;
    width: 50vh;
}
.formAuth{
    display: flex;
  
}
.formContain h2{
    text-align: center;
    margin-top: 1rem;
    color: var(--textColor2);
    margin-bottom: 2rem;
}
.inputsAuth input{
    border: none;
    padding: 10px;
   
    border-bottom: 1px solid var(--textColor2);
}
.inputsAuth label{
    color: var(--textColor2);
    display: none;
}
.formAuth{
    display: flex;
    width: 1005;
    flex-direction: column;
    gap: 1rem;
}
.deFlexInputs{
    display: flex;
    border: none;
    padding: 10px 0px;
    gap: 10px;
    border-bottom: 1px solid var(--textColor2);
}

.deFlexInputs input{
    width: 100%;
    border: none;
}
.deFlexInputs button{
    border: none;
    background-color: transparent;
    color:  var(--textColor2);
    cursor: pointer;
}
.btn {
    padding: 13px;
    border: none;
    border-radius: 30rem;
    background-color: var(--color1);
    color: var(--colorTextoBtns);
    margin-top: 10px;
    font-size: 16px;
    margin-right: 2rem;
    margin-left: 2rem;
}

.btn-disabled {
    padding: 13px;
    border: none;
    border-radius: 30rem;
    background-color: var(--color2);
    color: var(--colorTextoBtns);
    margin-top: 10px;
    font-size: 16px;
}

@media (max-width: 500px){
    .formContain{
        width: 100%;
    }
}