.btn-sesion{
    color: var(--textColor2);
    text-decoration: none;
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: var(--colorNavegacion);
    padding: 10px 20px;
    border-radius: 10rem;
    
}
.icon{
    font-size: 13px;
    background-color: var(--bgcolor);
    color: var(--textColor2);
    padding: 7px;
    border-radius: 100%;
    
}
.arrow-icon{
    font-size: 14px;
}