.scrolledMobile div .favoritos-btn{
    background-color: transparent !important;
    font-size: 16px;
    color: var(--textColor2) !important;
}
.favoritos-btn{ 
    position: fixed;
    bottom: 200px;
    right: 0;
    left: 92%; 
    color: var(--textColor);
    padding: 25px;
    border-radius: 100%;
   width: 2.5rem;
   height:2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   z-index: 2;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
}

.favoritos-btnFixed{
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 92%;
    background-color: var(--color1);
    color: var(--color2);
    padding: 25px;
    border-radius: 100%;
   width: 2.5rem;
   height:2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   z-index: 2;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
 
}
.favoritos-btn span{
    background-color:var(--color1);
    padding: 4px 7px;
    border-radius: 100%;
    font-size: 12px;    
   position: absolute;
   margin-top: -15px;
   margin-left: 1rem;
   color: var(--textColor);
}
 

.favoritos-btn strong{
    display: none;
}
@media (max-width: 900px) {

.favoritos-btn{ 
    position: relative;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; 
    box-shadow: none;
    padding: 0;
    flex-direction: column;
    font-size: 16px;
    display: flex;
    gap: 3px;
    flex-direction: column;
     padding-top: 4px; 
  }

.favoritos-btnFixed{
    position: fixed;
    bottom: 200px;
    right: 0;
    left: 92%;
    background-color: var(--color1);
    color: var(--color2);
    padding: 25px;
    border-radius: 100%;
   width: 2.5rem;
   height:2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   z-index: 2;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
 
}
.favoritos-btnFixed span{
    background-color:var(--color1);
    padding: 4px 7px;
    border-radius: 100%;
    font-size: 12px;    
   position: absolute;
   margin-top: -15px;
   margin-left: 1rem;
   color: var(--textColor);
}
.favoritos-btn span{
    background-color: var(--color2);
    padding: 4px 7px;
    border-radius: 100%;
    font-size: 12px;
   position: absolute;
   margin-top: -15px;
   margin-left: 1rem;
   color: var(--color1);

}

.favoritos-btn strong{
    display: flex;
    font-size: 12px;
    text-align: center;
    color: var(--textColor2);
    font-weight: 500;
}
}

.scrolledMobile {
    display: none;
 }
 
 .loading{
    display: flex;
    position: absolute;
background-color: rgba(0, 0, 0, 0.321);
height: 100vh;
width: 100%;
 }
 @media (max-width: 1024px) {
     .scrolledMobile {
         display: flex;
         position: fixed;
         width: 100%; 
         background-color: var(--colorNavegacion);
         bottom: 0;
         right: 0;
         left: 0;
         gap: 1rem;
         align-items: center;
         padding: 0px 4% 6px;
        justify-content: space-between;
         z-index: 12;
         box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.321);
         border-radius: 10px 10px 0px 0px ;
         
}
     
     
     .scrolledMobile a{
        font-size: 14px;
        display: flex;
        gap: 3px;
        flex-direction: column;
         color: var(--textColor2);
         padding-top: 10px;
        
     }
     .scrolledMobile strong{
         font-size: 12px;
        text-align: center;
        color: var(--textColor2);
        font-weight: 500;
     }
     .active{
       color: var(--color1)!important;
       border-top: 2px solid var(--color1);
      
}
     .active strong{
        color: var(--color1)!important;
       
    }
 
     .plus{ 
        background-color: var(--color1);
         color: var(--textColor2);
        padding: 0;
         width: 2.7rem;
         height:2.7rem;
         border-radius: 100%;
         box-shadow: 0px 55px 45px  var(--color1);
         display: flex;
         justify-content: center;
         text-align: center;
         align-items: center;
         border: none;
         font-size: 18px;
         margin-top:7px;
         
 }
 

 .puls:active, .plus:hover {  
    text-align: center;
 }
 
 .modalNavMobile {
     background-color: var(--colorNavegacion);
     display: flex;
     position: absolute;
     width: 100%;
     height: 50vh;
   
     right: 0;
     left: 0;
     bottom: -4.1%;
     padding: 20px 5%;
     flex-direction: column;
     gap: 2rem;
     border-radius: 10px 10px 0px 0px;
    
 }
 .modalNavMobileContain{
     height: 100vh;
     background-color: rgba(0, 0, 0, 0.355);
    
     display: flex;
     position: absolute;
     width: 100%;
    
     right: 0;
     left: 0;
     bottom: 4.2rem;
    
     flex-direction: column;
     gap: 2rem;
     border-radius: 10px 10px 0px 0px;
    
 }
 
 .modalNavMobile a{
     font-size: 16px;
     font-size: 16px;
     display: flex!important;
     align-items: center;
     border: 1px solid #aeadad4a;
     border-radius: 7px;
     padding: 8px 16px;
     text-decoration: none;
     width: 100%!important;
}


.scrolledMobile  .search-icon{
    font-size: 16px;
    border-radius: 10px;
    color: var(--textColor2);
    background-color: transparent;
   padding: 0;
  }
   .scrolledMobile  .inputSerch{
    font-size: 16px;
    display: flex;
    gap: 3px;
    flex-direction: column;
     color: var(--textColor2);
     padding-top: 15px;
     width: 3rem;
     align-items: center;
     background-color: transparent;
  }
  .scrolledMobile .inputSerch strong{
    display: flex;
    font-size: 12px;
    text-align: center;
    color: var(--textColor2);
    font-weight: 400;
    }
 
    .scrolledMobile .btnWhatsapp i {
        display: flex;
        font-size: 20px;
    }
    .scrolledMobile  .btnWhatsapp img {
        display: none;
    }
    .scrolledMobile  .btnWhatsapp{
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        position: relative;
        padding: 0;
        background-color: transparent;
        padding: 0;
        color: var(--colorTextoBtns);
    }
 }

 
 @media screen and (max-width: 967px) and (orientation: landscape){
     .modalNavMobile {
         background-color: var(--colorNavegacion);
         display: flex;
         position: absolute;
         width: 100%;
         height: 80vh;
       
         right: 0;
         left: 0;
         bottom: -3%;
         padding: 50px 5%;
         flex-direction: column;
         gap: 2rem;
         border-radius: 10px 10px 0px 0px;
        
     }
 }
 
 