
@media (max-width: 700px){
    .actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: flex-start; 
            padding: 0% 2%;
            padding-top: 0.5rem;  
      }
    .demo{
        border-radius: 0 30px 0 0px; 
       
    }
 
    .espaciobg{
        height: 4rem;
        background-color: var(--bgColor);
    }
}


.actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start; 
        padding: 0% 1%; 
  }
  
  .actions-title {   
    display: flex;
        margin: 0 0 0.25rem;
        color: var(--textColor2);
        flex-wrap: nowrap;
        justify-content: space-evenly;
  }
  
  .actions-content {
    display: flex;
    flex-direction: row;
  }
  
  .btnFlex {
    position: relative;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    padding: 0 1.25rem;
    line-height: 2.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    margin: 0.125rem;
    border-radius: 0.5rem;
    border: 2px solid var(--color2); 
    background-color: var(--color1);
    color: var(--color2); 
    text-decoration: none;
    transition: color 0.125s ease;
    overflow: hidden;
    cursor: pointer;
    vertical-align: middle;
    outline: none;
  }
  .btnFlex:before {
    background-color: var(--color2);
    content: "";
    display: flex;
    transform: scale(1, 0);
    transition: transform 0.25s ease;
    transform-origin: 50% 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 1px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  .btnFlex span {
    display: flex;
    position: relative;
    z-index: 1;
  }
  .btnFlex .icon {
    line-height: 2.125rem;
    font-size: 1.25rem;
    margin: 0 0.25rem 0 -0.25rem;
  }
  .btnFlex:hover, .btnFlex:active, .btnFlex:focus {
    color: var(--color1);
  }
  .btnFlex:hover:before, .btnFlex:active:before, .btnFlex:focus:before {
    transform: scale(1, 1);
  }
  .btnFlex.block {
    align-self: stretch;
    width: 100%;
    flex: 1 1 auto;
  }
  .btnFlex.bordered {
    background-color: transparent !important;
    color: #3F4C5C;
  }
  .btnFlex.bordered:before {
    background-color: #3F4C5C;
  }
  .btnFlex.bordered:hover, .btnFlex.bordered:active, .btnFlex.bordered:focus {
    color: #FFFFFF;
  }
  .btnFlex.rounded {
    border-radius: 1.1875rem;
  }
  .btnFlex.hard {
    border-radius: 0;
  }
  
  .btnFlex.invert {
    border: 2px solid var(--color1); 
    background-color: var(--color2);  
    color: var(--textColor2);
  }
  .btnFlex.blue:hover, .btnFlex.blue:active, .btnFlex.blue:focus {
    color: #4793FF;
  }
  .btnFlex.blue.bordered {
    color: #4793FF;
  }
  .btnFlex.blue.bordered:before {
    background-color: #4793FF;
  }
  .btnFlex.blue.bordered:hover, .btnFlex.blue.bordered:active, .btnFlex.blue.bordered:focus {
    color: #FFFFFF;
  }
  .btnFlex.green {
    background-color: #29C672;
    border-color: #29C672;
  }
  .btnFlex.green:hover, .btnFlex.green:active, .btnFlex.green:focus {
    color: #29C672;
  }
  .btnFlex.green.bordered {
    color: #29C672;
  }
  .btnFlex.green.bordered:before {
    background-color: #29C672;
  }
  .btnFlex.green.bordered:hover, .btnFlex.green.bordered:active, .btnFlex.green.bordered:focus {
    color: #FFFFFF;
  }
  .btnFlex.orange {
    background-color: #FFAE47;
    border-color: #FFAE47;
  }
  .btnFlex.orange:hover, .btnFlex.orange:active, .btnFlex.orange:focus {
    color: #FFAE47;
  }
  .btnFlex.orange.bordered {
    color: #FFAE47;
  }
  .btnFlex.orange.bordered:before {
    background-color: #FFAE47;
  }
  .btnFlex.orange.bordered:hover, .btnFlex.orange.bordered:active, .btnFlex.orange.bordered:focus {
    color: #FFFFFF;
  }
  .btnFlex.red {
    background-color: #FF4747;
    border-color: #FF4747;
  }
  .btnFlex.red:hover, .btnFlex.red:active, .btnFlex.red:focus {
    color: #FF4747;
  }
  .btnFlex.red.bordered {
    color: #FF4747;
  }
  .btnFlex.red.bordered:before {
    background-color: #FF4747;
  }
  .btnFlex.red.bordered:hover, .btnFlex.red.bordered:active, .btnFlex.red.bordered:focus {
    color: #FFFFFF;
  }