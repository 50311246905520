*{
  padding: 0 ;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s linear;
 
}
.color-picker label{
  background-color: #717171;
  padding: 5px;
  color: rgb(38, 38, 38);

}

 

.textLike{
  background-color: transparent;
border: none;
padding: 7px ; 
color: var(--color1);
font-size: 17px;
}
.unlike{
 
  color: var(--color1);  
}
.likeColor{  
  color: var(--bgColor) !important; 
  background-color: var(--color1);
}
 
.aside-1 {

  color: var(--textColor);
  /*background-color: #717171; */
}
.controles span{ 
  width: 50%; 
  
} 
.wrapper {
  display: flex;  
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center; 
}

.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}
 
 

@media all and (min-width: 600px) {
  .aside { flex: 1 0 0; }
}
 
 

 
.inputTodo{
  background-color: var(--color1); 
  color: var(--color3); 
  border-bottom: 2px solid var(--color1);
}

.inputTodoOff{
  background-color: var(--color2); 
  color: var(--color1); 
  border-bottom: 2px solid var(--color2);
}

h2,h3,h4,h5{
  color: var(--textColor);
}

h3 {
  font-size: 22px;
}
a{
  text-decoration: none;
}

 

 
 /* Definir animaciones */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Aplicar animaciones al modal */
.modalNav {
  animation: slideIn 0.4s forwards;
}

.modalNav-close {
  animation: slideOut 0.4s forwards;
}



/* Aplicar animaciones al modal */
.modalSearch{
  animation: slideUp 0.4s forwards;
}

.modalSearc-close {
  animation: slideDown 0.4s forwards;
}

 a, .detail-contain img, p, #SwiperSlide-scroll-products,  .categoriasInputs input,.cardProdcutSelected, .cardProdcutmasVendido,.cartIconFixed{
  opacity: 0;
  animation: slideFromLeft 0.5s ease-in-out forwards;
  -webkit-animation: slideFromLeft 0.5s ease-in-out forwards;
}

  
.modaldashboard{ opacity: 0; animation: slideUp 0.4s ease-in-out forwards; -webkit-animation: slideUp 0.4s ease-in-out forwards;
}

@keyframes slideFromLeft {
  from {
      opacity: 0;
      transform: translateX(-20%);
      -webkit-transform: translateX(-20%);
      -moz-transform: translateX(-20%);
      -ms-transform: translateX(-20%);
      -o-transform: translateX(-20%);
}
  to {
      opacity: 1;
      transform: translateX(0);
  }
}



@keyframes slideFromCenter {
  from {
      opacity: 0;
     
  }
  to {
      opacity: 10;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
      -moz-transform: translateY(0%);
      -ms-transform: translateY(0%);
      -o-transform: translateY(0%);
  }
  }

  
@keyframes slideUp {
  from {
      opacity: 0;
     
  }
  to {
      opacity: 1;
      transform: translateY(-3%);
      -webkit-transform: translateY(-3%);
      -moz-transform: translateY(-3%);
      -ms-transform: translateY(-3%);
      -o-transform: translateY(-3%);
}
  }
 
  
@keyframes slideDown {
  from {
      opacity: 1;
     
  }
  to {
      opacity: 0;
      transform: translateY(3%);
      -webkit-transform: translateY(3%);
      -moz-transform: translateY(3%);
      -ms-transform: translateY(3%);
      -o-transform: translateY(3%);
}
  }
  


  

  .btnAction{
    padding: 8px 8px;
    border-radius: 4px;
    border: none;
    background-color: var(--color1);
    color: var(--textColor3); 
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center; 
}

.btnNormal{
  padding: 8px 8px;
  border-radius: 4px;
  border: none;
  background-color: var(--color2);
  color: var(--color1); 
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center; 
}

.btnDelete{
  padding: 4px 4px;
  border-radius: 4px;
  border: none;
  background-color: #cb0a0a;
  color: #FFF; 
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center; 
}

.propina { 
  color: var(--textColor2);
  padding: 5px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

fieldset {
  color: var(--textColor2);
}

.colorTextoBotones{
  color: var(--colorTextoBtns);
}

.btnReset{
  color: var(--colorNavegacion);
    background-color: var(--color1);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px 8px;
    width: auto; 
}
.color-cell{
  width: 3%;
}

@media (max-width: 700px){
.links{
  margin-top:  -5px;
}
}

.links{
  margin-top:  -1.5em;
}



input, textarea, select{ 
  background-color: var(--bgColor);
  color: var(--textColor);
  border: none;
}

