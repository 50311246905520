.FooterContain{
    padding: 50px 10%;
    border-radius: 20px 20px 0 0;
    background-color: var(--color1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3rem; 
}

.FooterContain a img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: contain;
}

.footerText{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footerText h3{
    color: var(--textFooter) !important;
}
.footerText h2{
    color: var(--textFooter) !important;
    font-weight: 500;
    margin-bottom: 10px;
}
.footerText a{

    color: var(--textFooter) !important;
}
.socials{
    display: flex;
    gap: 2rem;
}
@media (max-width: 700px){
    .FooterContain{
    margin: 0;
    padding: 20px 4%;
    border-radius: 20px 20px 0 0;
    flex-direction: column;
}
}