.search-container { 
      flex-direction: row;
}

.search-container strong{
  align-self: stretch; 
    flex: 1 1 auto;
    font-size: 1.3rem;
}

.overlayInput{
  position: fixed;
  top: 0;
  
  bottom: 0;
  background-color: var(--color2); 
  z-index: 99; 
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
}
.modalInput{ 
  width: 30rem!important;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  border: none;
  padding: 20px;
}

.modalSearch {
  top: 4rem;
  left: 0; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  padding: 2px;
  z-index: 10000000000!important;
  width: 100%;
  height: auto;
  overflow-y:scroll;
  border-radius: 7px ;
  transition: all .40s ease;
  margin-top: 2rem;
}

.modalSearch .productCard { 
  background-color: var(--color2);
  border-radius: 7px;
  box-shadow: var(--color1) 0px 0px 3px 0px;  

}
.modalSearch .productCard a img
{         
  height: 7rem;
  width: 4rem; 
  object-fit: cover;
  object-position: center;
}

.modalSearch .productCard .cardText {
  padding-left: 5px;  
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;
}
.modalSearch img{  
object-fit: cover;
object-position: 0%;
width: 100%;
height: 100%;


border-radius: 10px;

}


.modalSearch p {
  display: block;

  text-decoration: none;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px;
  color: var(--textColor2)!important;
}

.inputSearch{
  background-color: var(--bgColor)!important;
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  width: 100%;
  color: var(--textColor2);
  display: flex;
  gap: 10px;
  align-items: center;

}

.input{
  width: 90%;
  height: 100%;
  padding: 6px 6px;
  background-color: transparent;
  border: 0.3px solid #b3b3b337;
  color: var(--color1)
}
.inputSerch{
  width: 100%;
  height: 100%;
  padding: 6px 6px;
  background-color: transparent;
  border: none;
}

.fondo-input{
  font-size: 14px;
    display: flex;
    gap: 3px;
    flex-direction: column;
    color: var(--color3); 
}
.modalSearch span{
  text-decoration: none; 
  color: var(--textColor2); 
  font-size: 18px;
}

.modalSearch:hover span{
  text-decoration: none; 
   font-size: 18px;
}

.modalSearch a{
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 20px;
  padding: 10px 10px;
  color: var(--color1);
  margin-bottom: 1rem;
}
.modalSearch a:hover h3{
  color: var(--colorNavegacion)!important;
}
.modalSearch a:hover {
 background-color: var(--color1);
 border-radius: 7px;
 transition: 0.5s ease all;
 color: var(--colorNavegacion)!important;

}
.modalSearch button{
  background-color: transparent;
  border: none;

}
.search-icon{
background-color: var(--color1);
color: var(--color3);
padding: 8px;
font-size: 17px;
cursor: pointer;
border-radius: 100%;
}

.close-icon{ 
  color: var(--color1);
  padding: 8px; 
  cursor: pointer;
  border-radius: 100%;
  }


.modalSearch::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.modalSearch::-webkit-scrollbar-thumb{
   background-color: var(--color1);
   width: 10px;
   border-radius:20px ;
   cursor: pointer;
}
.inputSerch span{
display: flex;
width: 100%;
}


@media (max-width: 1024px){

.inputSerch{
  display: none;
}
.modal p {
  font-size: 13px;
}
.modal a{
  
  gap: 10px;
  padding: 0px;

}
.modalInput{
  width: 100%!important;
  height: 80vh;
  border-radius: 20px 20px 0 0 ;

}

.overlayInput{
justify-content: end;
align-items: end;
place-content: end;
}
.search-icon{
  font-size: 1.4rem;
  }
  
}
